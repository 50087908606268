import React, { useMemo, useCallback, useEffect } from "react";
import { createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";

import Leaf from "./Leaf";
import Element from "./Element";

const EmptyState = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  },
];

const Viewer = ({ note }) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  
  const initialValue = useMemo(() => {
    return note ? JSON.parse(note.content) : EmptyState;
  }, [note]);

  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const renderElement = useCallback(props => <Element {...props} />, []);

  useEffect(() => {
    if (initialValue) {
      editor.children = initialValue;
      editor.onChange();
    }
  }, [initialValue]);

  return (
    <div className="viewer">
      <Slate editor={editor} initialValue={initialValue}>
        <Editable
          className="editable"
          placeholder=""
          renderLeaf={renderLeaf}
          renderElement={renderElement}
          readOnly
        />
      </Slate>
    </div>
  );
}

export default Viewer;
