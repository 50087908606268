import React, { useEffect, useRef, useState } from "react";

import ActionButton from "./ActionButton";
import Dropdown from "./Dropdown";

const MultipleActionButton = ({ 
  title,
  icon,
  loading,
  actions, 
  filled, 
  disabled 
}) => {

  const [showActions, setShowActions] = useState(false);

  const ref = useRef();
  const buttonRef = useRef();
  const dropdownRef = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showActions && ref.current && !ref.current.contains(event.target)) {
        setShowActions(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showActions]);

  const injectActionClosingFunction = (actions) => {
    return actions.map(action => {
      if (action.url) return action;

      return {
        ...action,
        onClick: () => {
          setShowActions(false);
          action.onClick();
        }
      };
    });
  }

  const positionDropdown = () => {
    const dropdownHeight = dropdownRef.current.clientHeight + 50;
    const dropdownTop = buttonRef.current.getBoundingClientRect().top;

    if (dropdownTop < dropdownHeight) {
      dropdownRef.current.style.bottom = "auto";
      dropdownRef.current.style.top = "100%";
    } else {
      dropdownRef.current.style.bottom = "100%";
      dropdownRef.current.style.top = "auto";
    }
  }

  const handleClick = () => {
    if (loading) return;
    setShowActions(show => !show);
    positionDropdown();
  }

  return (
    <div ref={ref} className="multiple-action-button">
      <ActionButton
        ref={buttonRef}
        title={title}
        icon={icon}
        loading={loading}
        filled={filled}
        disabled={disabled}
        aria-haspopup="true"
        aria-expanded={showActions ? "true" : "false"}
        onClick={handleClick}
      />
      <Dropdown
        ref={dropdownRef}
        show={showActions}
        submenu={injectActionClosingFunction(actions)}
      />
    </div>
  );
}

export default MultipleActionButton;
