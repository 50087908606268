import React, { Fragment, useEffect, useRef, useState } from "react";

import { MAX_TASKS } from "../../config/constants";

import ProgressBar from "../../../commons/ProgressBar";
import MultipleActionButton from "../../../commons/MultipleActionButton";

import CopyItemModal from "../Modal/CopyItemModal";
import EditTaskModal from "../Modal/EditTaskModal";

import Shine from "../Shine";
import Task from "./Task";

import plusIcon from "../../../assets/icons/plus.svg"

const ObjectiveInfo = ({ plan, objective, selectedTask, showAddTaskButton, onTaskSelect }) => {

  const [selectedObjective, setSelectedObjective] = useState(null);
  const [pickedTask, setPickedTask] = useState(null);

  const [showTaskPicker, setShowTaskPicker] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const objectiveRef = useRef();

  const totalTasksCount = selectedObjective?.tasks?.length;
  const completedTasksCount = selectedObjective?.tasks?.filter(t => t.status === "completed")?.length;
  const progress = (completedTasksCount / totalTasksCount * 100).toFixed(2);

  useEffect(() => {
    objectiveRef.current = objective;
    setSelectedObjective(objective);
  }, [objective]);

  const selectTask = (task) => {
    onTaskSelect && onTaskSelect(task);
  }

  const handleShineAnimationEnd = () => {
    const objective = objectiveRef.current;
    if (objective?.index !== selectedObjective?.index) {
      setSelectedObjective(objective);
    }
  }

  const handleTaskSelect = (task) => {
    setShowTaskPicker(false);
    setPickedTask(task);
    setShowEditModal(true);
  }

  const handleEditModalHide = () => {
    setShowEditModal(false);
    setPickedTask(null);
  }

  return (
    <Fragment>
      <div className="objective-info">
        <Shine trigger={objective} onAnimationEnd={handleShineAnimationEnd} />
        <div className="info right-padding">
          <span className="index">{`Objective ${selectedObjective?.index}`}</span>
          <span className="title" title={selectedObjective?.title}>{selectedObjective?.title}</span>
        </div>
        <div className="separator" />
        <div className="tasks-progress right-padding">
          <ProgressBar value={progress} />
          <span className="indicator">
            {`${completedTasksCount} of ${totalTasksCount} tasks completed (${progress}%)`}
          </span>
        </div>
        <div className="separator" />
        <div className="tasks">
          <span className="title">Tasks</span>
          <div className="items">
            {selectedObjective?.tasks?.map((task, index) => (
              <Task
                key={index}
                task={task}
                selected={task.index === selectedTask?.index}
                onSelect={selectTask}
              />
            ))}
          </div>
        </div>
        <div className="actions right-padding">
          {showAddTaskButton ? (
            <MultipleActionButton
              title="Add Task"
              icon={plusIcon}
              actions={[
                {
                  title: "Copy existing",
                  onClick: () => setShowTaskPicker(true)
                },
                {
                  title: "Create new",
                  onClick: () => setShowEditModal(true)
                }
              ]}
              disabled={selectedObjective?.tasks?.length >= MAX_TASKS}
            />
          ) : null}
        </div>
      </div>
      <CopyItemModal
        show={showTaskPicker}
        type="task"
        onSelect={handleTaskSelect}
        onCancel={() => setShowTaskPicker(false)}
      />
      <EditTaskModal
        operation="create"
        show={showEditModal}
        plan={plan}
        objective={objective}
        task={pickedTask}
        onSave={handleEditModalHide}
        onCancel={handleEditModalHide}
      />
    </Fragment>
  );
}

export default ObjectiveInfo;
