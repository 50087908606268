import React from "react";

import ProgressBar from "../../../commons/ProgressBar";

const TaskProgress = ({ task }) => {
  const progress = task?.progress ? Math.min(Math.max(0, Math.round(task.progress * 100)), 100) : 0;

  return (
    <div className="task-progress">
      <span className="status">{`${progress}% Complete`}</span>
      <ProgressBar value={progress} />
    </div>
  );
}

export default TaskProgress;
