import React from "react";

import Timestep from "./Timestep";
import SimpleTimestep from "./SimpleTimestep";
import EndIndicator from "./EndIndicator";

const SummaryTimeline = ({ objective, isPlanOwner }) => (
  <div className="summary-timeline">
    <SimpleTimestep title="Objective Start" date={objective?.started_at} />
    {objective?.tasks?.map((task, index) => (
      <Timestep
        key={index}
        task={task}
        isPlanOwner={isPlanOwner}
      />
    ))}
    <EndIndicator />
    <div className="line" />
  </div>
);

export default SummaryTimeline;
