import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import _ from "lodash";

import api from "../../../../api";

import Editor, { EmptyState } from "../../../../commons/Editor";
import Viewer from "../../../../commons/Editor/Viewer";

import Header from "./Header";

const NoteEditor = ({ plan, objective, task, isPlanOwner }) => {

  const [note, setNote] = useState(null);

  const [loading, setLoading] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const content = useRef();

  useEffect(() => {
    setNote(task?.note);
    reset();
  }, [task]);

  const saveNote = () => {
    setLoading(true);

    const data = { content: content.current };

    api.post(`/development_plans/${plan?.uuid}/objectives/${objective?.uuid}/tasks/${task?.uuid}/note`, data)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
          reload();
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const reload = () => {
    const params = new URLSearchParams(window.location.search);
    params.set("objective", objective?.index);
    params.set("task", task?.index);
    window.location.search = params;
  }

  const reset = () => {
    setLoading(false);
    setShowSave(false);
    setCanSave(false);
    content.current = null;
  }

  const handleContentChange = (value) => {
    content.current = JSON.stringify(value);

    const isNotEmpty = !_.isEqual(value, EmptyState);
    const hasSomeChange = !note || content.current !== note.content;

    if (!showSave && hasSomeChange) { setShowSave(true); }
    
    const save = isNotEmpty && hasSomeChange;
    if (canSave !== save) { setCanSave(save); }
  }

  const handleSaveClick = () => {
    const noteContent = content.current;

    if (_.isEmpty(noteContent) ||
        _.isNull(noteContent) ||
        _.isUndefined(noteContent)) {
      return;
    }
    
    saveNote();
  }

  if (!isPlanOwner && !note) {
    return null;
  }

  return (
    <div className="note-editor">
      <Header
        showSave={showSave}
        canSave={canSave}
        loading={loading}
        isPlanOwner={isPlanOwner}
        onSaveClick={handleSaveClick}
      />
      {isPlanOwner ? (
        <Editor
          note={note}
          onChange={handleContentChange}
        />
      ) : (
        <Viewer note={note} />
      )}
    </div>
  );
}

export default NoteEditor;
