import React from "react";
import I18n from "i18n-js";

import ActionButton from "../../../../commons/ActionButton";

import saveIcon from "../../../../assets/icons/edit-confirm.svg";

const Header = ({ showSave, canSave, loading, isPlanOwner, onSaveClick }) => (
  <div className="note-header">
    <div className="title">
      {isPlanOwner ? I18n.t("pdp.my_notes") : I18n.t("pdp.notes")}
    </div>
    <div className="buttons">
      {showSave ? (
        <ActionButton
          title={I18n.t("pdp.actions.save")}
          icon={saveIcon}
          loading={loading}
          disabled={!canSave}
          onClick={onSaveClick}
        />
      ) : null}
    </div>
  </div>
);

export default Header;
