import { object, string, date } from "yup";

const taskSchema = object({
  title: string().required("Title is required"),
  target_date: date()
    .required("Target date is required")
    .test(
      "is-target-date-valid",
      "Target date must be between the development plan start and end dates",
      (target_date, context) => {
        const { start_date, end_date } = context.options.context || {};
        
        if (target_date && start_date && end_date) {
          return target_date >= start_date && target_date <= end_date;
        }

        return false;
      }
    ),
}).required();

export default taskSchema;