import React, { useState, useEffect } from "react";
import { useLocation } from "react-use";
import I18n from "i18n-js";
import _ from "lodash";

import Selector from "./Selector";
import StatusIndicator from "./StatusIndicator";

const PlanSelector = ({ team, plans, showMyPlan }) => {
  
  const { pathname } = useLocation();

  const [plan, setPlan] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const index = pathname.lastIndexOf("pdp/") + 4;
    const planUuid = pathname.substring(index).split("?")[0].replace("/", "");

    const plan = plans.find(plan => planUuid === plan?.development_plan?.uuid);
    if (plan) {
      setPlan(plan);
    }

  }, [plans, pathname]);

  useEffect(() => {
    let items = plans.filter(plan => !plan?.logged_user);
    items = _.sortBy(items, ["first_name", "last_name"])
      .map(getMemberPlanDropdownItem);

    if (showMyPlan) {
      const planItem = plans.find(plan => plan?.logged_user);
      items.unshift(getMyPlanDropdownItem(planItem));
    }

    setItems(items);
  }, [plans, plan]);

  const getMyPlanDropdownItem = (planItem) => {
    const url = getPlanUrl(planItem);
    const status = url ? planItem?.development_plan?.status : "no_plan";

    const isSelected = plan?.development_plan &&
      planItem?.development_plan?.uuid === plan?.development_plan?.uuid;
    
    return {
      title: I18n.t("pdp.plan_selector.my_plan"),
      tooltip: I18n.t("pdp.plan_selector.my_plan"),
      url: url,
      clickable: Boolean(url),
      selected: isSelected,
      indicator: <StatusIndicator status={status} />
    };
  }

  const getMemberPlanDropdownItem = (planItem) => {
    const url = getPlanUrl(planItem);
    const status = url ? planItem?.development_plan?.status : "no_plan";
    
    const isDraft = planItem?.development_plan?.status === "draft";
  
    const isSelected = plan?.development_plan &&
      planItem?.development_plan?.uuid === plan?.development_plan?.uuid;
  
    return {
      title: getUserName(planItem),
      tooltip: planItem?.email,
      url: isDraft ? null : url,
      clickable: Boolean(url) && !isDraft,
      selected: isSelected,
      indicator: <StatusIndicator status={status} />
    };
  };

  const getUserName = (plan) => {
    const lastName = plan?.last_name ? plan?.last_name?.substr(0, 1) + "." : "";
    return plan?.first_name ? `${plan?.first_name} ${lastName}` : "";
  }

  const getPlanUrl = (plan) => {
    if (!plan?.development_plan?.uuid) { return null; }
    return `/teams/${team.uuid}/pdp/${plan?.development_plan?.uuid}?locale=${I18n.locale}`;
  }

  const title = plan?.logged_user
    ? I18n.t("pdp.plan_selector.my_plan")
    : getUserName(plan);

  return (
    <Selector
      className="plan-selector"
      title={title}
      items={items}
    />
  );
}

export default PlanSelector;
