import I18n from "i18n-js";
import * as moment from "moment";
import _, { times } from "lodash";

export const getHumanDate = (src) => {
  const date = moment.utc(src)
    .locale(I18n.locale.toLowerCase());

  if (date.isBefore(moment().subtract(1, "days"))) {
    return date.format("L");
  }
  
  return date.fromNow();
}

export const getHumanDateTime = (src) => {
  const date = moment.utc(src)
    .locale(I18n.locale.toLowerCase());

  if (date.isBefore(moment().subtract(1, "year"))) {
    return date.format("MMMM Do, YYYY h:mm a");
  }
  
  return date.format("MMMM Do, h:mm a");
}

export const getDateUTCAtMidnight = (src) => {
  if (!src) { return null; }
  
  const date = Date.UTC(
    src.getUTCFullYear(), 
    src.getUTCMonth(), 
    src.getUTCDate()
  );
  return new Date(date);
}

export const getTimeUTC = (src) => {
  if (!src) { return null; }
  const date = parseTimeStringToDate(src);
  return getTimeStr(date.getUTCHours(), date.getUTCMinutes());
}

export const getTimeLocal = (utc) => {
  if (!utc) { return null; }
  const date = parseTimeStringToDate(utc);

  const offset = date.getTimezoneOffset();

  const timestamp = date.getTime() - offset * 60 * 1000;
  const local = new Date(timestamp);
  
  return getTimeStr(local.getHours(), local.getMinutes());
}

export const parseTimeString = (time) => {
  const date = parseTimeStringToDate(time);
  return getTimeStr(date.getHours(), date.getMinutes());
}

export const getNextQuarterHour = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const nextQuarterMinutes = Math.ceil(minutes / 15) * 15;

  if (nextQuarterMinutes === 60) {
    now.setHours(now.getHours() + 1, 0);
  } else {
    now.setMinutes(nextQuarterMinutes);
  }

  return getTimeStr(now.getHours(), now.getMinutes());
}

export const getTimeStr = (hours, minutes, seconds = null, milli = null) => {
  let str = getStringPaddedNumber(hours);
  str += `:${getStringPaddedNumber(minutes)}`;

  if (seconds) {
    str += `:${getStringPaddedNumber(seconds)}`;
  }

  if (milli) {
    str += `:${getStringPaddedNumber(milli)}`;
  }

  return str;
}

const parseTimeStringToDate = (time) => {
  const [hours, minutes, seconds] = time?.split(":") || [];
  const date = new Date();
  
  date.setHours(
    _.defaultTo(_.toNumber(hours), 0),
    _.defaultTo(_.toNumber(minutes), 0),
    _.defaultTo(_.toNumber(seconds), 0)
  );

  return date;
}

const getStringPaddedNumber = (number) => {
  return number.toString().padStart(2, "0");
}