import React, { Component, Fragment, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";

import useRoutingState from "../hooks/useRoutingState";

import api from "../api";

import { getTimeLocal, getTimeUTC } from "../services/date";

import SelectedPdpTitle from "./components/SelectedPdpTitle";
import PlanForm from "./components/PlanForm";

const formatDate = (date) => moment.utc(date).local().format("YYYY-MM-DD");

const getFormattedPlan = (plan) => ({
  ...plan,
  start_date: formatDate(plan?.start_date),
  target_date: formatDate(plan?.target_date),
  objectives: plan?.objectives?.map(getFormattedObjective),
  time: getTimeLocal(plan?.time),
});

const getFormattedObjective = (objective) => ({
  ...objective,
  tasks: objective?.tasks?.map(getFormattedTask)
});

const getFormattedTask = (task) => ({
  ...task,
  target_date: formatDate(task?.target_date)
});

const Edit = ({ teams, team, personal, plan }) => {
  useRoutingState();
  
  const formattedPlan = useMemo(() => getFormattedPlan(plan));

  const [loading, setLoading] = useState(false);

  const savePlan = (formData) => {
    setLoading(true);

    const data = {
      ...formData,
      time: getTimeUTC(formData?.time)
    };

    api.put(`/development_plans/${plan?.uuid}`, data)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.saved_successfully")}</b>);

          if (res.data && res.data.redirect_to) {
            window.location.assign(res.data.redirect_to);
          }
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const handleCancelClick = () => {
    window.history.back();
  }

  const renderPageHeader = () => (
    <div className="pdp-row page-header">
      <SelectedPdpTitle
        teams={teams}
        selectedTeam={team}
        personal={personal}
      />
    </div>
  );

  const renderPageContent = () => (
    <div className="page-content">
      <PlanForm
        edit
        loading={loading}
        plan={formattedPlan}
        onSaveClick={savePlan}
        onCancelClick={handleCancelClick}
      />
    </div>
  );

  return (
    <Fragment>
      <div className="pdp edit">
        <div className="container-fluid">
          {renderPageHeader()}
          <div className="pdp-row">
            {renderPageContent()}
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class EditComponent extends Component {
  render() {
    return <Edit {...this.props} />;
  }
}