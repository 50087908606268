import React, { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Progress from "react-svg-progress";
import moment from "moment";
import I18n from "i18n-js";

import DateInput from "../../../commons/DateInput";

import Input from "../Input";
import FormSection from "./FormSection";
import ObjectivesAndTasks from "./ObjectivesAndTasks";
import TimeWithVicky from "./TimeWithVicky";

import { defaultObjective } from "./ObjectiveList";

import planSchema from "../../config/validation/plan";

const formatDate = (date) => moment.utc(date).local().format("YYYY-MM-DD");

const getDefaultValues = (plan) => ({
  title: plan?.title || "",
  start_date: plan?.start_date || null,
  target_date: plan?.target_date || null,
  objectives: plan?.objectives || [defaultObjective(1)],
  time: plan?.time,
  frequency: plan?.frequency || "1",
  periodicity: plan?.periodicity || "week",
  repeat: plan?.repeat || [],
  ending: {
    type: "manual",
    after: "4",
    ...plan?.ending,
    on: plan?.ending?.on ? formatDate(plan?.ending?.on) : null
  }
});

const PlanForm = ({ edit, plan, loading, onSaveClick, onCancelClick }) => {

  const defaultValues = useMemo(() => getDefaultValues(plan));
  const form = useForm({ defaultValues, resolver: yupResolver(planSchema) });
  const { control, watch, handleSubmit, formState: { errors } } = form;

  console.log(watch(), errors);

  const today = moment().format("YYYY-MM-DD");

  const handleCancelClick = (event) => {
    event.preventDefault();
    onCancelClick && onCancelClick();
  }

  return (
    <FormProvider {...form} defaultValues={defaultValues}>
      <form onSubmit={handleSubmit(onSaveClick)}>
        <div className="title">
          {edit ? "Edit your plan" : "Create your plan"}
        </div>
        <FormSection>
          <div className="pdp-form-row">
            <Controller
              name="title"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <Input
                  placeholder="Plan Label"
                  error={errors.title?.message}
                  inputRef={ref}
                  {...props}
                />
              )}
            />
            <div className="dummy" />
          </div>
        </FormSection>
        <div className="separator" />
        <FormSection
          title="Timeframe"
          description="Set a timeframe for your plan."
        >
          <div className="pdp-form-row">
            <Controller
              name="start_date"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <DateInput
                  placeholder="Start Date"
                  error={errors.start_date?.message}
                  inputRef={ref}
                  {...props}
                />
              )}
            />
            <Controller
              name="target_date"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <DateInput
                  placeholder="End Date"
                  min={today}
                  error={errors.target_date?.message}
                  inputRef={ref}
                  {...props}
                />
              )}
            />
          </div>
        </FormSection>
        <div className="separator" />
        <ObjectivesAndTasks />
        <div className="separator" />
        <TimeWithVicky />
        <div className="buttons">
          <button
            className="cancel-btn"
            disabled={loading}
            onClick={handleCancelClick}
          >
            {I18n.t("common.cancel")}
          </button>
          <button
            type="submit"
            className={`save-btn ${loading ? "loading" : ""}`}
            disabled={loading}
          >
            <span className="content">
              {edit ? "Save Changes" : "Create Plan"}
            </span>
            <Progress color="#FFFFFF" size="15px" />
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

export default PlanForm;
