import React, { Component, Fragment, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import api from "../api";

import useRoutingState from "../hooks/useRoutingState";

import ActionButton from "../commons/ActionButton";
import MultipleActionButton from "../commons/MultipleActionButton";

import PageTitle from "./components/PageTitle";
import ObjectiveSelector from "./components/ObjectiveSelector";
import SummaryTimeline from "./components/SummaryTimeline";
import ShareSummaryModal from "./components/Modal/ShareSummaryModal";

import shareIcon from "../assets/icons/share_outline.svg";
import downloadIcon from "../assets/icons/download.svg";

const MimeTypes = {
  "pdf": "application/pdf",
  "csv": "text/csv",
  "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
};

const Summary = ({ plan, isPlanOwner, showBack }) => {
  useRoutingState();

  const [objective, setObjective] = useState(plan?.objectives[0]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileDownload = (format) => {
    setLoading(true);
    api.get(`/development_plans/${plan?.uuid}/export.${format}`, {
      responseType: "blob"
    }).then(response => {
      
      const blob = new Blob([response.data], {
        type: MimeTypes[format]
      });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${plan?.title}.${format}`;
      
      document.body.appendChild(a);
      a.click();
      a.remove();

      window.URL.revokeObjectURL(url);

      setLoading(false);
    }).catch(err => {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(<b>{err.response.data.message}</b>);
      } else {
        toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
      }
      console.error(err);
      setLoading(false);
    });
  }

  const renderPageHeader = () => (
    <div className="pdp-row page-header">
      <PageTitle title="Plan Summary" showBack={showBack} />
    </div>
  );

  const renderPageContent = () => (
    <div className="page-content">
      <div className="header">
        <span className="title">{plan?.title}</span>
        <div className="action-buttons">
          {isPlanOwner ? (
            <ActionButton
              title="Share"
              icon={shareIcon}
              onClick={() => setShowShareModal(true)}
            />
          ) : null}
          <MultipleActionButton
            title="Download"
            icon={downloadIcon}
            loading={loading}
            actions={[
              {
                title: "PDF",
                onClick: () => handleFileDownload("pdf")
              },
              {
                title: "Excel (XLSX)",
                onClick: () => handleFileDownload("xlsx")
              }
            ]}
          />
        </div>
      </div>
      <div className="details">
        <div className="objectives">
          <span className="section-title">Objectives</span>
          <ObjectiveSelector
            objectives={plan?.objectives}
            onSelect={setObjective}
          />
        </div>
        <SummaryTimeline
          objective={objective}
          isPlanOwner={isPlanOwner}
        />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="pdp summary">
        <div className="container-fluid">
          {renderPageHeader()}
          <div className="pdp-row">
            {renderPageContent()}
          </div>
        </div>
      </div>
      <ShareSummaryModal
        plan={plan}
        show={showShareModal}
        onShare={() => setShowShareModal(false)}
        onCancel={() => setShowShareModal(false)}
      />
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class SummaryComponent extends Component {
  render() {
    return <Summary {...this.props} />;
  }
}