import React, { Component } from "react";

import Card from "../commons/Card"; 

import PdpSelector from "./components/PdpSelector";

const Error = ({ teams, team, personal, isTeamLeadOrAbove, title, content }) => {

  const renderPageHeader = () => (
    <div className="pdp-row page-header">
      <div className="selectors">
        <PdpSelector
          teams={teams}
          selectedTeam={team}
          personal={personal}
          isTeamLeadOrAbove={isTeamLeadOrAbove}
        />
      </div>
    </div>
  );

  return (
    <div className="pdp error">
      <div className="container-fluid">
        {renderPageHeader()}
        <div className="page-content">
          <Card title={title} content={content} />
        </div>
      </div>
    </div>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class ErrorComponent extends Component {
  render() {
    return <Error {...this.props} />;
  }
}