import React, { Component, Fragment, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import useRoutingState from "../hooks/useRoutingState";

import api from "../api";

import { getTimeUTC } from "../services/date";

import SelectedPdpTitle from "./components/SelectedPdpTitle";
import PlanForm from "./components/PlanForm";

const New = ({ teams, team, personal }) => {
  useRoutingState();

  const [loading, setLoading] = useState(false);

  const savePlan = (formData) => {
    setLoading(true);

    const data = {
      ...formData,
      time: getTimeUTC(formData.time),
      team_uuid: team?.uuid
    };

    console.log("DDD", formData, data);

    api.post("/development_plans", data)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.saved_successfully")}</b>);

          if (res.data && res.data.redirect_to) {
            window.location.assign(res.data.redirect_to);
          }
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const handleCancelClick = () => {
    window.history.back();
  }

  const renderPageHeader = () => (
    <div className="pdp-row page-header">
      <SelectedPdpTitle
        teams={teams}
        selectedTeam={team}
        personal={personal}
      />
    </div>
  );

  const renderPageContent = () => (
    <div className="page-content">
      <PlanForm
        loading={loading}
        onSaveClick={savePlan}
        onCancelClick={handleCancelClick}
      />
    </div>
  );

  return (
    <Fragment>
      <div className="pdp new">
        <div className="container-fluid">
          {renderPageHeader()}
          <div className="pdp-row">
            {renderPageContent()}
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class NewComponent extends Component {
  render() {
    return <New {...this.props} />;
  }
}