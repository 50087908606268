import React, { useEffect, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import toast from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../../api";

import InternalModal from "../../../commons/InternalModal";
import LoadingButton from "../../../commons/LoadingButton";
import Slider from "../../../commons/Slider";
import Textarea from "../../../commons/Textarea";

const UpdateTaskProgressModal = ({ plan, objective, task, show, onCancel }) => {

  const taskProgress = task?.progress ? task?.progress * 100 : 0;

  const [loading, setLoading] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const [loadedSuggestion, setLoadedSuggestion] = useState(false);

  const [progress, setProgress] = useState(taskProgress);
  const [description, setDescription] = useState(null);

  const [anim, animApi] = useSpring(() => ({
    from: { opacity: 0, scale: [0, 0, 0] },
    to: { opacity: 1, scale: [1, 1, 1] },
  }));

  const buttonTitle = loadedSuggestion
    ? I18n.t("pdp.modals.update_task_progress.buttons.save.title")
    : I18n.t("pdp.modals.update_task_progress.buttons.update.title");

  useEffect(() => {
    if (task && show) {
      setProgress(taskProgress);
    }
  }, [task, show]);

  const getProgressSuggestion = () => {
    setLoading(true);
    api.post(`/development_plans/${plan?.uuid}/objectives/${objective?.uuid}/tasks/${task?.uuid}/progress/suggestion`, { description })
      .then(res => {
        console.log(res);
        const progress = res.data.progress || taskProgress;
        setProgress(progress);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(err);
        setProgress(taskProgress);
      })
      .finally(() => {
        setLoadedSuggestion(true);
        setLoading(false);
        showProgressSlider();
      });
  }

  const updateProgress = () => {
    setLoading(true);

    const data = { progress: progress / 100.0, description };
    
    api.patch(`/development_plans/${plan?.uuid}/objectives/${objective?.uuid}/tasks/${task?.uuid}/progress`, data)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
          reload(task?.index)
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const reload = (taskIndex) => {
    const params = new URLSearchParams(window.location.search);
    params.set("objective", objective?.index);
    params.set("task", taskIndex);
    window.location.search = params;
  }

  const resetState = () => {
    setTimeout(() => {
      setLoading(false);
      setCanSave(false);
      setProgress(0);
      setDescription(null);
      setLoadedSuggestion(false);
    }, 1000);
  }

  const showProgressSlider = () => {
    animApi.start({
      from: { opacity: 0, scale: [0, 0, 0] },
      to: { opacity: 1, scale: [1, 1, 1] },
    });
  }

  const handleSaveClick = () => {
    loadedSuggestion ? updateProgress() : getProgressSuggestion();
  }

  const handleSlideChange = (_, value) => {
    setProgress(value);
    if (value > taskProgress) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }

  const handleDescriptionChange = (event) => {
    event.preventDefault();
    setDescription(event.target.value);
    setCanSave(true);
  }

  const handleCancelClick = (event) => {
    event.preventDefault();
    resetState();
    onCancel && onCancel();
  }

  const renderModal = () => {
    return (
      <div className="pdp-update-task-progress-modal">
        <div className="title">
          {I18n.t("pdp.modals.update_task_progress.title")}
        </div>
        {loadedSuggestion ? (
          <animated.div className="slider-anim" style={anim}>
            <div className="subtitle">{I18n.t("pdp.modals.update_task_progress.subtitle")}</div>
            <div className="slider-wrapper">
              <Slider
                name="progress"
                value={progress}
                minValue={0}
                maxValue={100}
                onChange={handleSlideChange}
              />
            </div>
          </animated.div>
        ) : null}
        <Textarea
          label={I18n.t("pdp.modals.update_task_progress.inputs.description.label")}
          placeholder={I18n.t("pdp.modals.update_task_progress.inputs.description.placeholder")}
          value={description}
          rows={5}
          onChange={handleDescriptionChange}
        />
        <div className="buttons">
          <button
            className="cancel-btn"
            disabled={loading}
            onClick={handleCancelClick}
          >
            {I18n.t("common.cancel")}
          </button>
          <LoadingButton
            filled
            loading={loading}
            disabled={!canSave}
            className="save-btn"
            title={buttonTitle}
            onClick={handleSaveClick}
          />
        </div>
      </div>
    );
  }

  return (
    <InternalModal
      type="update-task-progress"
      size="lg"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderModal()}
      showModalBody={false}
    />
  );
}

export default UpdateTaskProgressModal;
