import React from "react";

const Card = ({ icon, title, content }) => {

  return (
    <div className="card">
      <div className="header">
        {title ? (
          <div className="title">
            {icon ? <img className="icon" src={icon} /> : null}
            <h2>{title}</h2>
          </div>
        ) : null}
      </div>
      <div dangerouslySetInnerHTML={{
        __html: content
      }} />
    </div>
  );
}

export default Card;
